import React, { useCallback, useEffect, useState } from "react";
import '../../css/login-page.css';
import { Images } from "../../assets";
import { checkBillExist } from "../../services/apis";
import { Button, Divider, Form, Input, Row, Space, message, theme } from 'antd';
import LoadingBase from "../../components/LoadingBase";
import { useSelector } from "react-redux";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import moment from "moment";
import { debounce } from "lodash";

const CheckBillPage = () => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const userReducer = useSelector(state => state?.userReducer);
    const [status, setStatus] = useState(); //1: Hop le, 2: Khong ho le
    const [data, setData] = useState();

    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    let barcodeScan = "";
    const handleChangeCode = (e) => {
        setCode(e.target.value);
        setStatus(0)
    }

    const handleCheckBill = async (barcodeString) => {
        console.log("handleCheckBill barcodeString", barcodeString);
        if (!barcodeString || barcodeString?.length === 0) {
            message.warning("Vui lòng nhập mã vận đơn");
            return;
        }
        setStatus(0);
        try {
            const params = {
                code: barcodeString,
                branchId: userReducer?.data?.branch_info?.id,
                checkById: userReducer?.data?.user_info?.id
            };
            setLoading(true);
            const res = await checkBillExist(params);
            setLoading(false);
            consoleLogUtil("checkBillExist", res);
            if (res) {
                if (res?.status === 1) { //Hoa don hop le
                    setStatus(1);
                } else if (res?.status === 2) { //Hoa don da check in truoc day
                    setStatus(2);
                    setData(res?.data);
                } else {
                    setStatus(3);
                }
            } else {
                setStatus(3);
            }
        } catch (error) {
            consoleLogUtil("errrror", error)
            setLoading(false);
            setStatus(3);
        }

    }
    const debouncedHandleCheckBill = useCallback(debounce(handleCheckBill, 500), []);

    const handleScan = (barcodeString) => {
        console.log("barcodeString", barcodeString);
        if (loading) return;
        setCode(prevCode => {
            console.log("prevCode", prevCode);
            setStatus(0);
            debouncedHandleCheckBill(barcodeString);
            return barcodeString;
        });
        barcodeScan = "";
    }
    useEffect(() => {
        function handleKeyDown(e) {
            //remove specia key: mũi tên, caplock, shift, backspace, F1234...
            const specialKeys = [
                8, 9, 16, 17, 18, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46,
                91, 92, 93, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123
            ];

            // Ignore special keys
            console.log("eeee", e);
            console.log("e.keyCode", e.keyCode);
            if (specialKeys.includes(e.keyCode)) {
                return;
            }
            console.log("e.key", e.key);
            if (e.keyCode === 13 && barcodeScan.length > 6 && barcodeScan !== "Enter" && !loading) {
                // code = SPXVN04051995927C, VTP09333333333.., GHN1234567890123456789..., 89098888ABBC..., SPEVN99999
                // const regex = /^(SPX|VTP|GHN|8|SPEVN|VNPOST)[A-Za-z0-9]*$/;
                // const regex = /[A-Za-z0-9]*$/;
                // if (regex.test(barcodeScan)) {
                //     handleScan(barcodeScan);
                // }
                handleScan(barcodeScan);
                return;
            }

            barcodeScan += e.key;
            console.log("barcodeScan", barcodeScan);
            
            setTimeout(() => {
                barcodeScan = "";
            }, 200)
        }
        document.addEventListener('keydown', handleKeyDown);

        return function cleanUp() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])
    return (
        <div className='container-content'>
            <Row>
                <Space>
                    <p style={{ fontSize: 18, fontWeight: 600, color: 'black' }}>Mã vận đơn</p>
                    <Input value={code} onChange={handleChangeCode} style={{ fontSize: 20, fontWeight: 800, width: 350, height: 48, color: 'black' }} />
                    <Button type="primary" onClick={() => handleCheckBill(code)} style={{ height: 48, width: 120, fontSize: 18 }}>Kiểm tra</Button>
                </Space>
            </Row>

            <Divider orientation="left" style={{ marginTop: 30 }}>Kết quả kiểm tra</Divider>
            {
                status === 1 ?
                    <Row align={"middle"}>
                        <img src={Images.ic_status} style={{ width: 40, height: 40 }} />
                        <p style={{ color: '#4cae4f', fontSize: 20, fontWeight: 800, paddingLeft: 12 }}>Mã vận đơn hợp lệ</p>
                    </Row>

                    : null
            }
            {
                status === 2 ?
                    <>
                        <Row align={"middle"}>
                            <img src={Images.ic_error} style={{ width: 40, height: 40 }} />
                            <p style={{ color: 'red', fontSize: 20, fontWeight: 800, paddingLeft: 12 }}>Mã vận đơn {code} không hợp lệ do đã từng quét</p>
                        </Row>
                        <Row align={"middle"} style={{ marginTop: 12 }}>
                            {/* <img src={Images.ic_dates} style={{ width: 24, height: 24 }} /> */}
                            <p style={{ color: 'black', fontSize: 16, paddingLeft: 0 }}>Thời gian quét trước đây: {data?.check_in ? <span style={{ fontSize: 16, fontWeight: 700, color: 'black' }}>{moment(data?.check_in).format("HH:mm:ss DD/MM/YYYY")}</span> : ''}</p>

                        </Row>

                        {/* <div style={{ marginTop: 50 }}>
                            <Button danger type="primary" onClick={handleGetStaffInfo}>Lấy thông tin đơn hàng</Button>
                        </div> */}
                    </>

                    : null
            }


            <LoadingBase loading={loading} />
        </div>

    );
}


export default CheckBillPage;